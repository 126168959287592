@font-face {
	font-family: "ilbianconero";
	font-display: block;
	src: url("https://assets.prod.ilbianconero.com/build/app.ilbianconero.f12d54194c4290c0e36df7388a006422.eot?#iefix") format("embedded-opentype"),
url("https://assets.prod.ilbianconero.com/build/app.ilbianconero.11616d08c2c2e8055ea34db72831b585.woff") format("woff"),
url("https://assets.prod.ilbianconero.com/build/app.ilbianconero.e2f6779462ac724c74229ea1cd9593ad.woff2") format("woff2"),
url("https://assets.prod.ilbianconero.com/build/app.ilbianconero.296bb2d9577796aeeecc7093361d975e.ttf") format("truetype"),
url("https://assets.prod.ilbianconero.com/build/app.ilbianconero.d2612229c7efefdf60ba5ef99b739744.svg#ilbianconero") format("svg");
}

.cm-ico {
	line-height: 1;
}

.cm-ico:before {
	font-family: ilbianconero !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.cm-arrow:before {
	content: "\f101";
}
.cm-clock:before {
	content: "\f102";
}
.cm-close:before {
	content: "\f103";
}
.cm-comment:before {
	content: "\f104";
}
.cm-external-link:before {
	content: "\f105";
}
.cm-facebook:before {
	content: "\f106";
}
.cm-home:before {
	content: "\f107";
}
.cm-like:before {
	content: "\f108";
}
.cm-linkedin:before {
	content: "\f109";
}
.cm-loader:before {
	content: "\f10a";
}
.cm-login:before {
	content: "\f10b";
}
.cm-messenger:before {
	content: "\f10c";
}
.cm-newsletter:before {
	content: "\f10d";
}
.cm-notification:before {
	content: "\f10e";
}
.cm-play:before {
	content: "\f10f";
}
.cm-preference:before {
	content: "\f110";
}
.cm-refresh:before {
	content: "\f111";
}
.cm-search:before {
	content: "\f112";
}
.cm-share:before {
	content: "\f113";
}
.cm-twitter:before {
	content: "\f114";
}
.cm-whatsapp:before {
	content: "\f115";
}
.cm-win:before {
	content: "\f116";
}
.cm-x:before {
	content: "\f117";
}
.cm-youtube:before {
	content: "\f118";
}
